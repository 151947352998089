<template>
  <div>
    <p class="compont_p">绑定微信</p>
    <div class="flex_row_aic">
      <img style="width: 24px; height: 24px" src="@/assets/img/login/login_weixin.png" alt="" />
      <p>绑定微信后可使用微信扫码登录</p>
    </div>
    <el-button type="primary" class="custombtn" @click="getPoints">绑定微信</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  created() {
    // this.$api("account.getAddress").then((res) => {
    //   console.log(res);
    // });
  },
  methods: {
    getPoints() {},
  },
};
</script>

<style lang="less" scoped>
.flex_row_aic {
  margin: 25px 0;
  img {
    margin-right: 10px;
  }
}
.custombtn {
  margin-left: 30px;
}
</style>
